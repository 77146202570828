<template>
  <div>
    <p>Datos Generales de la Entidad</p>
    <v-container fluid>
      <v-container v-if="!dataList" style="height: 400px">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            Obteniendo informacíon
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
      <v-row v-if="dataList">
        <v-col cols="10">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-autocomplete
                :items="itemsDocumentsPais"
                item-text="description"
                item-value="id"
                label="Tipo de Documento Fiscal"
                v-model="id_documents"
                @change="_validaDocumento()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="n_documents"
                type="number"
                label="N° de Documento Fiscal"
                @change="_validaDocumento()"
              ></v-text-field>
            </v-col>
            <v-col v-if="id_documents != 2" cols="12" md="12">
              <v-text-field v-model="names" label="Nombres"></v-text-field>
            </v-col>
            <v-col v-if="id_documents != 2" cols="12" md="6">
              <v-text-field
                v-model="surname"
                label="Primer Apellido"
              ></v-text-field>
            </v-col>
            <v-col v-if="id_documents != 2" cols="12" md="6">
              <v-text-field
                v-model="second_surname"
                label="Segundo Apellido"
              ></v-text-field>
            </v-col>
            <v-col v-if="id_documents == 2" cols="12" md="6">
              <v-text-field
                v-model="tradename"
                label="Nombre Comercial"
              ></v-text-field>
            </v-col>
            <v-col v-if="id_documents == 2" cols="12" md="6">
              <v-text-field
                v-model="business_name"
                label="Razón Social"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="6">
              <v-text-field
                v-model="phone"
                label="Teléfono"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="mail"
                label="Correo"
                type="email"
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="6">
              <v-autocomplete
                item-text="name"
                item-value="id"
                :items="itemsPais"
                label="País"
                @change="_getState(id_pais)"
                v-model="id_pais"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                :items="itemsState"
                item-text="name"
                item-value="id"
                @change="_getCity(id_state)"
                v-model="id_state"
                label="Departamento"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                :items="itemsCity"
                item-text="name"
                item-value="id"
                @change="_getTown(id_city)"
                v-model="id_city"
                label="Provincia"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                :items="itemsTown"
                item-text="name"
                item-value="id"
                label="Distrito"
                v-model="id_town"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="id_documents != 2" cols="12" md="6">
              <v-autocomplete
                :items="itemsSex"
                item-text="name"
                item-value="id"
                v-model="id_sex"
                label="Sexo"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="id_documents != 2" cols="12" md="6">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Fecha de Nacimiento"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                rows="3"
                v-model="address"
                label="Dirección"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                rows="3"
                v-model="notes"
                label="Notas Importantes"
              ></v-textarea>
            </v-col>

            <v-col cols="12" md="12">
              <v-btn
                v-if="statusData == 1"
                @click="_postEntitie()"
                color="success"
                class="mr-2"
                elevation="0"
                >Guardar</v-btn
              >
              <v-btn
                v-if="statusData == 2"
                @click="_updatetEntitie()"
                color="success"
                class="mr-2"
                elevation="0"
                >Actualizar</v-btn
              >
              <v-btn color="error" elevation="0" @click="regresar"
                >Cancelar</v-btn
              >
            </v-col>

            <v-col cols="8">
              <v-autocomplete
                v-model="$store.state.data_id_role"
                :items="$store.state.itemsDataRoleList"
                item-text="name"
                item-value="id"
                label="Cambiar Tipo de Role"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-btn @click="cambiarRole()" x-large block>CAMBIAR</v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="2">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 mt-5"
                fab
                dark
                elevation="0"
                large
                v-bind="attrs"
                v-on="on"
                @click="$store.state.modalPhones = !$store.state.modalPhones"
                color="blue-grey darken-3"
              >
                <v-icon dark> mdi-phone </v-icon>
              </v-btn>
            </template>
            <span>Teléfonos</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 mt-5"
                fab
                dark
                elevation="0"
                large
                v-bind="attrs"
                v-on="on"
                @click="
                  $store.state.modalContacts = !$store.state.modalContacts
                "
                color="blue-grey darken-3"
              >
                <v-icon dark> mdi-account-multiple </v-icon>
              </v-btn>
            </template>
            <span>Contactos</span>
          </v-tooltip>

          <!-- <v-tooltip v-if="this.$route.params.id" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 mt-5"
                fab
                dark
                elevation="0"
                large
                v-bind="attrs"
                v-on="on"
                @click="$store.state.modalCuentas = !$store.state.modalCuentas"
                color="blue-grey darken-3"
              >
                <v-icon dark> mdi-bank </v-icon>
              </v-btn>
            </template>
            <span>Cuentas</span>
          </v-tooltip> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
export default {
  name: "controlEntitiesCom",
  props: {
    // dataClien: 1,
  },
  data() {
    return {
      id_documents: "",
      n_documents: "",
      names: "",
      surname: "",
      second_surname: "",
      tradename: "",
      business_name: "",
      phone: "",
      mail: "",
      id_pais: "",
      id_state: "",
      id_city: "",
      id_town: "",
      id_sex: "",
      address: "",
      notes: "",
      statusData: 1,
      id_entitie: "",
      id_role: "",
      dataList: true,

      //id_role: this.$route.params.id_role,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
    };
  },
  computed: {
    ...mapState([
      "itemsDocumentsPais",
      "itemsPais",
      "itemsState",
      "itemsCity",
      "itemsTown",
      "itemsSex",
      "modalCuentas",
    ]),
  },
  mounted() {
    this._getDocumentsPais();
    this._getPais();
    this._getRole();
    this._getAccountsNumber();

    this.id_pais = JSON.parse(localStorage.getItem("datos")).id_PaisBrach;

    if (this.$route.params.id_role) {
      this.$store.state.id_role_actual = this.$route.params.id_role;
    }
    this._callState();
    this._getSex();
    this._validatyDocuments();

    this._validaData();
  },
  methods: {
    ...mapActions([
      "_getDocumentsPais",
      "_getPais",
      "_getState",
      "_getCity",
      "_getTown",
      "_getSex",
      "_getEntities",
      "_getAgents",
      "_getConsigners",
      "_getNotify",
      "_getColoaders",
      "_getNavieras",
      "_getRole",
      "_getPhone",
    ]),
    regresar() {
      this.$router.go(-1);
    },
    _validatyDocuments() {
      if (JSON.parse(localStorage.getItem("datos")).id_PaisBrach == 139) {
        this.id_documents = 2;
      }
    },

    _callState() {
      if (this.id_pais > 0) {
        this._getState(this.id_pais);
      }
    },

    async _getAccountsNumber() {
      var vm = this;
      var config = {
        method: "post",
        url:
          process.env.VUE_APP_URL_MAIN + "getAccountsNumber/" +
          vm.$route.params.id,
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/json",
        },
      };
      await axios(config)
        .then(function (response) {
          vm.$store.state.itemsDataAccountsNumberList = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    clenData() {
      (this.names = ""),
        (this.surname = ""),
        (this.second_surname = ""),
        (this.tradename = ""),
        (this.business_name = ""),
        (this.date = ""),
        (this.n_documents = ""),
        (this.address = ""),
        (this.notes = "");
    },

    _validaDocumento() {
      var vm = this;

      var data = {
        ic: vm.id_documents + vm.n_documents,
      };
      var config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "getEntitiesListIc",
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          if (response.data.data.length > 0) {
            vm.$swal({
              icon: "warning",
              title: "Lo sentimos",
              text: "Este documento ya existe en la base de datos",
              showDenyButton: true,

              confirmButtonText: "Modificar datos existentes",
              denyButtonText: `Cancelar registro`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                vm.$router.push(
                  "/ficheros/folderEntities/control/edit/" +
                    response.data.data[0].id
                );
                vm._validaData();
              } else if (result.isDenied) {
                vm.$swal("Registro cancelado", "", "info");
              }
            });
            vm.statusData = 2;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    _validaData() {
      var vm = this;
      if (vm.$route.params.id) {
        vm.dataList = false;
        vm.$store.state.house_Id_get = vm.$route.params.id;
        var data = {
          id: vm.$route.params.id,
        };
        var config = {
          method: "post",
          url: process.env.VUE_APP_URL_MAIN + "getEntitiesListId",
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            vm.names = response.data.data[0].names;
            vm.surname = response.data.data[0].surname;
            vm.second_surname = response.data.data[0].second_surname;
            vm.tradename = response.data.data[0].tradename;
            vm.business_name = response.data.data[0].business_name;
            vm.date = response.data.data[0].birthday;
            vm.n_documents = response.data.data[0].document;
            vm.address = response.data.data[0].address;
            vm.notes = response.data.data[0].notes;
            vm.id_pais = response.data.data[0].id_pais;
            vm.id_state = response.data.data[0].id_state;
            vm._getCity(response.data.data[0].id_state);
            vm.id_city = response.data.data[0].id_city;
            vm._getTown(response.data.data[0].id_city);
            vm.id_town = response.data.data[0].id_town;
            vm.id_sex = response.data.data[0].id_sex;
            vm.id_documents = response.data.data[0].id_document;
            vm.statusData = 2;
            vm.id_entitie = response.data.data[0].id;
            vm.id_role = response.data.data[0].id_role;
            vm.dataList = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },

    async _postEntitie() {
      var vm = this;
      var dataEntitie = {
        names: vm.names,
        surname: vm.surname,
        second_surname: vm.second_surname,
        tradename: vm.tradename,
        business_name: vm.business_name,
        birthday: vm.date,
        document: vm.n_documents,
        address: vm.address,
        notes: vm.notes,
        status: 1,
        id_pais: vm.id_pais,
        id_state: vm.id_state,
        id_city: vm.id_city,
        id_town: vm.id_town,
        id_sex: vm.id_sex,
        id_document: vm.id_documents,
        id_branch: JSON.parse(localStorage.getItem("branch")), // JSON.parse(localStorage.getItem("dataUser"))[0].id_branch,
        dataPhones: vm.$store.state.itemsListPhone
          ? vm.$store.state.itemsListPhone
          : [],
        dataContacts: vm.$store.state.itemsListContact
          ? vm.$store.state.itemsListContact
          : [],
      };
      var config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "addEntities",
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/json",
        },
        data: dataEntitie,
      };
      await axios(config)
        .then(function (response) {
          if (response.data.status == 200) {
            var idInsert = response.data.data.insertId;

            vm._postRoleEntitie(idInsert);
          } else {
            vm.$swal({
              icon: "error",
              title: "Lo sentimos",
              text: "Ha ocurrido un error en el proceso",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          vm.$swal({
            icon: "error",
            title: "Lo sentimos",
            text: "Ha ocurrido un error en el proceso " + error,
          });
        });
    },

    cambiarRole() {
      var vm = this;
      var data = {
        id_role: vm.$store.state.data_id_role,
      };
      var config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "editRole/" + vm.$route.params.id,
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          if (response.data.status == 200) {
            vm.$swal({
              icon: "success",
              title: "Excelente",
              text: "Role actualizada con éxito",
            });
            if (vm.$route.params.id) {
              vm._getEntities(vm.id_role);

              vm.$router.back();
            }
          } else {
            vm.$swal({
              icon: "error",
              title: "Lo sentimos",
              text: "Ha ocurrido un error en el proceso",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async _updatetEntitie() {
      var vm = this;
      var dataEntitie = {
        names: vm.names,
        surname: vm.surname,
        second_surname: vm.second_surname,
        tradename: vm.tradename,
        business_name: vm.business_name,
        birthday: vm.date,
        document: vm.n_documents,
        address: vm.address,
        notes: vm.notes,
        id_pais: vm.id_pais,
        id_state: vm.id_state,
        id_city: vm.id_city,
        id_town: vm.id_town,
        id_sex: vm.id_sex,
        id_document: vm.id_documents,
        id_branch: JSON.parse(localStorage.getItem("branch")),
      };
      var config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "editEntitie/" + vm.id_entitie,
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/json",
        },
        data: dataEntitie,
      };
      
      await axios(config)
        .then(function (response) {
          if (response.data.status == 200) {
            vm.$swal({
              icon: "success",
              title: "Excelente",
              text: "Entidad actualizada con éxito",
            });
            if (vm.$route.params.id) {
              vm._getEntities(vm.id_role);

              vm.$router.back();
            }
          } else {
            vm.$swal({
              icon: "error",
              title: "Lo sentimos",
              text: "Ha ocurrido un error en el proceso",
            });
          }
        })
        .catch(function (error) {
          vm.$swal({
            icon: "error",
            title: "Lo sentimos",
            text: "Ha ocurrido un error en el proceso " + error,
          });
        });
    },

    async _postRoleEntitie(id_entitie) {
      var vm = this;

      var dataEntitie = {
        id_entities: id_entitie,
        id_role: vm.$store.state.id_role_actual,
      };
      var config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "addRoleEntitie",
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/json",
        },
        data: dataEntitie,
      };
      await axios(config)
        .then(function (response) {
          if (response.data.status == 200) {
            if (vm.$store.state.id_role_actual == 1) {
              vm._getAgents();
              vm.clenData();
              vm.$store.state.modalAgente = false;
            } else if (vm.$store.state.id_role_actual == 11) {
              vm._getEntities(11);
              vm._getConsigners();
              vm._getNotify();

              vm.clenData();
              vm.$store.state.modalEntitie = false;
            } else if (vm.$store.state.id_role_actual == 17) {
              vm._getColoaders();
              vm.clenData();
              vm.$store.state.modalColoader = false;
            } else if (vm.$store.state.id_role_actual == 2) {
              vm._getNavieras();
              vm.clenData();
              vm.$store.state.modalNaviera = false;
            }
            vm._getEntities(vm.$store.state.id_role_actual);
            vm.$swal({
              icon: "success",
              title: "Excelente",
              text: "Proceso éxitoso",
            });

            // if (vm.dataClien) {
            //   vm.$router.back();
            // }
          } else {
            vm.$swal({
              icon: "error",
              title: "Lo sentimos",
              text: "Ha ocurrido un error en el proceso",
            });
          }
        })
        .catch(function (error) {
          vm.$swal({
            icon: "error",
            title: "Lo sentimos",
            text: "Ha ocurrido un error en el proceso " + error,
          });
        });
    },
  },
};
</script>
